// tslint:disable-next-line:class-name
export class APP_ROUTES {
    public static root = '';
    public static slash = '/';

    // * Pre auth Layout */
    public static login = 'login';
    public static forgotPassword = 'forgot-password';
    public static changePassword = 'reset_password';
    public static setSocialPassword = 'set_password';
    public static register = 'register';
    public static verification = 'verification';
    public static aboutUs = 'aboutUs';
    public static termsAndConditions = 'termsAndConditions';
    public static privacyPolicy = 'privacyPolicy';

    // * post auth Layoyt */
    public static home = 'home';
    public static myProfile = 'my-profile';
    public static dashboard = 'dashboard';
    public static resetPassword = 'reset-password';
    public static accountList = 'account-list';
    public static AccountEdit = 'account-edit';
    public static ManageUsers = 'manage-users';
    public static SitesList = 'sites';
    public static TournamentsList = 'tournaments';
    public static TournamentDetail = 'detail';
    public static TournamentEdit = 'edit';
    public static TournamentAdd = 'add';
    public static BookingsList = 'bookings';
    public static BookingsDetail = 'detail';
    public static AddUser = 'add-user';
    public static SiteDetail = 'site-detail';
    public static SitesAdd = 'sites-add';
    public static SiteEdit = 'site-edit';
    public static editProfile = 'edit';
    public static PayoutList = 'payouts';
    public static PayoutDetail = 'detail';
    public static UserBookings = 'user-bookings';
    public static UserBookingDetails = 'detail';
    public static ManageRoles = 'manage-roles';
    public static DashboardSiteOverView = 'sites-overview';
    public static DashboardCurrentReports = 'current-reports';
    public static DashboardCurrentToolbox = 'current-toolbox';
    public static DashboardKeyFigures = 'overview-key-figures';
    public static MappingUsers = 'mapping';
    public static RoleDetails  = 'detail';
    public static ManageAccount  = 'manage-account';
    public static ComingAccount  = 'coming-soon';
    public static ManageProject  = 'manage-projects';
    public static ManageInstructions = 'manage-instructions';
    public static Insturctions_template = 'templates';
    public static Instructions = "instructions";
    public static InstructionsReports = "report";
    public static NoPermission  = 'no-permission';
    public static ManageToolbox  = 'manage-toolbox';
    public static ManageToolboxMettings = 'meetings';
    public static ManageToolBoxReport = 'report';
    public static Toolbox_Templates = 'templates';
    public static ManageLMRA  = 'manage-lmra';
    public static LMRA_Templates  = 'templates';
    public static LMRA_CheckList  = 'checklist';
    public static LMRA_Report  = 'report';
    public static Send_Message  = 'send-message';
    public static ManageIncidents  = 'manage-incidents';
    public static ManageIncidentsList = 'incidents-list';
    public static ManageIncidentsApprovedList = 'approve-incidents-list';
    public static dataAnalysisList = 'data-analysis';
    public static Messages  = 'messages';

}
