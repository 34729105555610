export const functions = [
    {name: 'ALL', value: ''},
    {name: 'Toolbox' , value: 'toolbox' },
    {name: 'LMRA' , value: 'lmra' },
    {name: 'Ereignismeldung' , value: 'ereignismeldung' },
    {name: 'Unterweisungen', value: 'unterweisungen' }
];

// all,toolbox,lmra,incident,induction
export const dataAnalysisFunction = [
    {name: 'ALL', value: 'all'},
    {name: 'Toolbox' , value: 'toolbox' },
    {name: 'LMRA' , value: 'lmra' },
    {name: 'Ereignismeldung' , value: 'incident' },
    {name: 'Unterweisungen', value: 'induction' }
];
