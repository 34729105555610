import { environment } from '../../../environments/environment';

export class ApiRoutes {
    // api base url
    static apiV1 = 'api/v1/';
    public static apiBaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV1;
    public static get uploadImage(): string { return this.apiBaseUrl + 'image_upload'; }
    public static get storeSite(): string { return this.apiBaseUrl + 'add_site'; }
    public static get getSites(): string { return this.apiBaseUrl + 'list_site'; }
    public static get getCommonService(): string { return this.apiBaseUrl + 'common_services'; }
    public static get getSite(): string { return this.apiBaseUrl + 'edit_site'; }
    public static get deleteSite(): string { return this.apiBaseUrl + 'delete_site'; }
    public static get updateSite(): string { return this.apiBaseUrl + 'update_site'; }
    public static get login(): string { return this.apiBaseUrl + 'login'; }
    public static get register(): string { return this.apiBaseUrl + 'user/register'; }
    public static get profile(): string { return this.apiBaseUrl + 'profile'; }
    public static get updateProfile(): string { return this.apiBaseUrl + 'update_profile'; }
    public static get emailVerification(): string { return this.apiBaseUrl + 'player/activate/?token='; }
    public static get forgotPassword(): string { return this.apiBaseUrl + 'forgot_password'; }
    public static get checkResetToken(): string { return this.apiBaseUrl + 'token_verify'; }
    public static get changePassword(): string { return this.apiBaseUrl + 'reset_password'; }
    public static get resetPassword(): string { return this.apiBaseUrl + 'change_password'; }
    public static get setPassword(): string { return this.apiBaseUrl + 'set_password'; }
    public static get deleteAccount(): string { return this.apiBaseUrl + 'user_delete'; }
    public static get getAccountList(): string { return this.apiBaseUrl + 'user_lists'; }
    public static get blockUnblockAccount(): string { return this.apiBaseUrl + 'user_status'; }
    public static get addTournament(): string { return this.apiBaseUrl + 'add_tournament'; }
    public static get getTournaments(): string { return this.apiBaseUrl + 'list_tournament'; }
    public static get deleteTournament(): string { return this.apiBaseUrl + 'delete_tournament'; }
    public static get getTournament(): string { return this.apiBaseUrl + 'edit_tournament'; }
    public static get deleteTournamentImages(): string { return this.apiBaseUrl + 'delete_tournament_image'; }
    public static get updateTournament(): string { return this.apiBaseUrl + 'update_tournament'; }
    public static get getBookings(): string { return this.apiBaseUrl + 'booking_list'; }
    public static get getBookingDetail(): string { return this.apiBaseUrl + 'booking_detail'; }
    public static get bookingCancel(): string { return this.apiBaseUrl + 'booking_cancel'; }
    public static get getUserFriends(): string { return this.apiBaseUrl + 'friend_list'; }
    public static get getUserBookings(): string { return this.apiBaseUrl + 'user_booking'; }
    public static get getPayouts(): string { return this.apiBaseUrl + 'booking_payout'; }
    public static get updateUserSite(): string { return this.apiBaseUrl + 'user_site'; }
    public static get getUsers(): string { return this.apiBaseUrl + 'user_lists'; }
    public static get getRoles(): string { return this.apiBaseUrl + 'list_role'; }
    public static get storeUser(): string { return this.apiBaseUrl + 'user_add'; }
    public static get activeRoles(): string { return this.apiBaseUrl + 'role_lists'; }
    public static get getPermissions(): string {return this.apiBaseUrl + 'permission_type'; }
    public static get storeRole(): string { return this.apiBaseUrl + 'add_role';  }
    public static get getRole(): string { return this.apiBaseUrl + 'edit_role';  }
    public static get updateRole(): string { return this.apiBaseUrl + 'update_role';  }
    public static get deleteRole(): string { return this.apiBaseUrl + 'delete_role';  }
    public static get updateStatusRole(): string { return this.apiBaseUrl + 'role_status'; }
    public static get updateStatusUser(): string { return this.apiBaseUrl + 'user_status'; }
    public static get deleteUser(): string { return this.apiBaseUrl + 'user_delete'; }
    public static get getUser(): string { return this.apiBaseUrl + 'user_edit'; }
    public static get updateUser(): string { return this.apiBaseUrl + 'user_update'; }
    public static get getSupervisor(): string { return this.apiBaseUrl + 'supervisor_lists'; }
    public static get uploadUserCsv(): string { return this.apiBaseUrl + 'user_import'; }
    public static get getMappedUserList(): string { return this.apiBaseUrl + 'mapping_user_list'; }
    public static get updateStatusMappedUser(): string { return this.apiBaseUrl + 'mapping_user_status'; }
    public static get deleteUserMapping(): string { return this.apiBaseUrl + 'mapping_user_delete'; }
    public static get getPersonalPoolingList(): string { return this.apiBaseUrl + 'pooling_list'; }
    public static get getPersonalPoolingUsersByName(): string { return this.apiBaseUrl + 'pooling_list_user'; }
    public static get updatePersonalPoolingStatus(): string { return this.apiBaseUrl + 'pooling_status'; }
    public static get getProjects(): string { return this.apiBaseUrl + 'project_lists'; }
    public static get editProject(): string { return this.apiBaseUrl + 'edit_project'; }
    public static get add_project(): string { return this.apiBaseUrl + 'add_project'; }
    public static get update_project(): string { return this.apiBaseUrl + 'update_project'; }
    public static get add_client(): string { return this.apiBaseUrl + 'add_client'; }
    public static get client_list(): string { return this.apiBaseUrl + 'client_list'; }
    public static get project_status(): string { return this.apiBaseUrl + 'project_status'; }
    public static get getProjectsAll(): string { return this.apiBaseUrl + 'project_list'; }
    public static get site_manager_list(): string { return this.apiBaseUrl + 'site_manager_list'; }
    public static get toolbox_lists(): string { return this.apiBaseUrl + 'toolbox_lists'; }
    public static get toolbox_excuted_list() : string { return this.apiBaseUrl + 'toolboxexecuted_lists';}
    public static get toolbox_project_lists(): string { return this.apiBaseUrl + 'toolbox_project_lists'; }
    public static get add_toolbox(): string { return this.apiBaseUrl + 'add_toolbox'; }
    public static get add_toolbox_template() : string { return this.apiBaseUrl+'add_toolboxtemplate'}
    public static get list_toolbox_template() : string { return this.apiBaseUrl+'list_toolboxtemplate'}
    public static get delete_toolbox_template() : string { return this.apiBaseUrl+'delete_toolboxtemplate'}
    public static get edit_toolbox_template() : string { return this.apiBaseUrl+'edit_toolboxtemplate'}
    public static get update_toolbox_template() : string { return this.apiBaseUrl+'update_toolboxtemplate'}
    public static get delete_toolbox_template_question() : string { return this.apiBaseUrl+'delete_toolboxtemplate_question'}
    public static get filter_toolbox_template() : string { return this.apiBaseUrl+'list_toolboxtemplate_filter'}
    public static get edit_toolbox(): string { return this.apiBaseUrl + 'edit_toolbox'; }
    public static get update_toolbox(): string { return this.apiBaseUrl + 'update_toolbox'; }
    public static get toolbox_status(): string { return this.apiBaseUrl + 'toolbox_status'; }
    public static get toolbox_delete(): string { return this.apiBaseUrl + 'toolbox_delete'; }
    public static get lmra_lists(): string { return this.apiBaseUrl + 'lmra_lists'; }
    public static get add_lmra(): string { return this.apiBaseUrl + 'add_lmra'; }
    public static get edit_lmra(): string { return this.apiBaseUrl + 'edit_lmra'; }
    public static get image_upload(): string { return this.apiBaseUrl + 'image_upload'; }
    public static get add_template(): string { return this.apiBaseUrl + 'add_template'; }
    public static get list_template(): string { return this.apiBaseUrl + 'list_template'; }
    public static get edit_template(): string { return this.apiBaseUrl + 'edit_template'; }
    public static get delete_template(): string { return this.apiBaseUrl + 'delete_template'; }
    public static get update_template(): string { return this.apiBaseUrl + 'update_template'; }
    public static get list_checklist(): string { return this.apiBaseUrl + 'list_checklist'; }
    public static get add_checklist(): string { return this.apiBaseUrl + 'add_checklist'; }
    public static get edit_checklist(): string { return this.apiBaseUrl + 'edit_checklist'; }
    public static get update_checklist(): string { return this.apiBaseUrl + 'update_checklist'; }
    public static get delete_template_question(): string { return this.apiBaseUrl + 'delete_template_question'; }
    public static get delete_checklist(): string { return this.apiBaseUrl + 'delete_checklist'; }
    public static get list_template_filter(): string { return this.apiBaseUrl + 'list_template_filter'; }
    public static get lmra_status(): string { return this.apiBaseUrl + 'lmra_status'; }
    public static get department(): string { return this.apiBaseUrl + 'department'; }
    public static get sub_department(): string { return this.apiBaseUrl + 'sub_department'; }
    public static get delete_project(): string { return this.apiBaseUrl + 'project_delete'; }
    public static get receipt_user_list(): string { return this.apiBaseUrl + 'receipt_user_list'; }
    public static get add_message(): string { return this.apiBaseUrl + 'add_message'; }
    public static get incident_list(): string { return this.apiBaseUrl + 'incident_list'; }
    public static get incident_edit(): string { return this.apiBaseUrl + 'incident_edit'; }
    public static get incident_report_update(): string { return this.apiBaseUrl + 'incident_report_update'; }
    public static get incident_question_list(): string { return this.apiBaseUrl + 'incident_question_list'; }
    public static get incident_list_approved(): string { return this.apiBaseUrl + 'incident_report_list'; }
    public static get update_incident_approved(): string { return this.apiBaseUrl + 'incident_report_apporved'; }
    public static get incident_delete(): string { return this.apiBaseUrl + 'incident_delete'; }
    public static get app_user_list(): string { return this.apiBaseUrl + 'app_user_list'; }
    public static get mobile_user_list(): string { return this.apiBaseUrl + 'mobile_user_list'; }
    public static get add_incident(): string { return this.apiBaseUrl + 'add_incident'; }
    public static get client_projects(): string { return this.apiBaseUrl + 'client_projects'; }
    public static get list_construction_sites_overview(): string {return this.apiBaseUrl + 'site_overview'; }
    public static get detail_construction_sites_overview(): string {return this.apiBaseUrl + 'site_overview_detail'; }
    public static get list_site_current_report(): string {return this.apiBaseUrl + 'incident_current_report'; }
    public static get list_toolbox_report(): string {return this.apiBaseUrl + 'toolbox_current_report'; }
    public static get pdf_render(): string {return this.apiBaseUrl + 'pdf_render'; }
    public static get incident_image_upload(): string {return this.apiBaseUrl + 'incident_image_upload'; }
    public static get incident_image_delete(): string {return this.apiBaseUrl + 'incident_image_delete'; }
    public static get induction_list(): string {return this.apiBaseUrl + 'induction_list'; }
    public static get induction_add(): string {return this.apiBaseUrl + 'induction_add'; }
    public static get induction_edit(): string {return this.apiBaseUrl + 'induction_edit'; }
    public static get induction_update(): string {return this.apiBaseUrl + 'induction_update'; }
    public static get induction_delete(): string {return this.apiBaseUrl + 'induction_delete'; }
    public static get induction_template_add() : string { return this.apiBaseUrl + 'add_inductiontemplate'}
    public static get induction_template_edit(): string {return this.apiBaseUrl + 'edit_inductiontemplate'; }
    public static get induction_template_list(): string {return this.apiBaseUrl + 'list_inductiontemplate'; }
    public static get induction_template_delete(): string {return this.apiBaseUrl + 'delete_inductiontemplate'; }
    public static get induction_template_update(): string {return this.apiBaseUrl + 'update_inductiontemplate'; }
    public static get filter_induction_template() : string { return this.apiBaseUrl+'list_inductiontemplate_filter'}
    public static get report_induction_list() : string { return this.apiBaseUrl+'inductionexecuted_lists'}
    public static get list_message(): string {return this.apiBaseUrl + 'list_message'; }
    public static get message_detail(): string {return this.apiBaseUrl + 'message_detail'; }
    public static get list_data_analysis(): string { return this.apiBaseUrl + 'data_analysis'; }
    public static get projectsupervisors(): string { return this.apiBaseUrl + 'projectsupervisors'; }
    public static get updates_incident_status(): string { return this.apiBaseUrl + 'updates_incident_status'; }
    public static get user_project_list(): string { return this.apiBaseUrl + 'user_project_list'; }
    public static get incident_usernames(): string { return this.apiBaseUrl + 'incident_usernames'; }
    public static get pooling_specific_details(): string { return this.apiBaseUrl + 'pooling_specific_details'; }
    public static get filterpoolusers(): string { return this.apiBaseUrl + 'filterpoolusers'; }
    public static get dashboard_filter(): string { return this.apiBaseUrl + 'dashboard_filter'; }
    public static get incident_view(): string { return this.apiBaseUrl + 'incident_view'; }
    public static get incident_questions_report(): string { return this.apiBaseUrl + 'incident_questions_report'; }
    public static get toolbox_clone(): string { return this.apiBaseUrl + 'toolbox_clone'; }
    public static get toolbox_reports(): string { return this.apiBaseUrl + 'toolbox_reports'; }
    public static get toolbox_pdf_reports(): string { return this.apiBaseUrl + 'toolbox_pdf_reports'; }
    public static get lmra_pdf_reports(): string { return this.apiBaseUrl + 'lmra_pdf_reports'; }
    public static get induction_pdf_reports(): string { return this.apiBaseUrl + 'induction_pdf_reports'; }
    public static get update_client(): string { return this.apiBaseUrl + 'update_client'; }
    
}

